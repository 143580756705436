import React from "react"
import Layout from "../components/layouts/investments_layout"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Meta from "../components/meta"

import LostDog from "../images/luke-jones-QPmZOzhjtu4-unsplash.jpg"


const NotFoundPage = ({ data }) => (
    <Layout>
        <Meta title="404" description="Page not found" />
        <Container>
            <Row>
                <Col className="align-self-center text-center">
                    <h1>404</h1>
                    <h2>Page not found</h2>
                    <img className="imageCard" src={LostDog} alt="404 Lost Dog"></img>
                </Col>
            </Row>
        </Container>
    </Layout>
)
export default NotFoundPage;
